import React from 'react';
// mui //
import Button from '@mui/material/Button';
// hook //
import useResponsive from 'hook/common/useResponsive';
// third party libraries //
import hexToRgba from 'hex-to-rgba';

const ExtendedButton = (props) => {
  const {
    variant, customStyle, children, color, ...rest
  } = props;
  const { upLg, theme } = useResponsive();
  const primary = color || theme.palette.primary.main;
  const { disabledBackground } = theme.palette.action;

  const isVariant = (data) => variant === data;

  const backgroundStyle = () => {
    if (isVariant('secondary')) return 'white';
    if (isVariant('third')) return 'transparent';
    if (isVariant('four')) return hexToRgba(primary, '0.1');
    return primary;
  };

  const borderStyle = () => {
    if (isVariant('third') || isVariant('four')) return '0px';
    const style = isVariant('secondary') ? primary : 'white';
    return `1px solid ${style}`;
  };

  const colorStyle = () => {
    const style = isVariant('secondary') || isVariant('third') || isVariant('four')
      ? primary
      : 'white';
    return style;
  };

  const hoverStyle = () => {
    if (isVariant('secondary')) return { color: primary, background: 'white' };
    if (isVariant('third')) {
      return {
        color: primary,
        background: 'transparent',
      };
    }
    if (isVariant('four')) {
      return {
        color: primary,
        background: hexToRgba(primary, '0.1'),
      };
    }
    return { color: 'white', background: primary };
  };

  const hoverInverseStyle = () => {
    if (isVariant('secondary')) {
      return {
        color: 'white',
        background: primary,
        border: '1px solid white',
      };
    }
    if (isVariant('third') || isVariant('four')) {
      return {
        color: primary,
        background: hexToRgba(primary, '0.1'),
      };
    }
    return {
      color: primary,
      background: 'white',
      border: `1px solid ${primary}`,
    };
  };

  /**
   *  style
   */
  const style = {
    width: 130,
    height: 44,
    borderRadius: 50,
    textTransform: 'none',
    background: backgroundStyle(),
    fontWeight: 600,
    border: borderStyle(),
    padding: '16px',
    color: colorStyle(),
    transition: '.5s',
    '&:hover': hoverStyle(),
    '&:disabled': {
      background: disabledBackground,
    },
    ...(upLg && {
      '&:hover': hoverInverseStyle(),
    }),
  };

  return (
    <Button sx={{ ...style, ...customStyle }} {...rest}>
      {children}
    </Button>
  );
};

export default ExtendedButton;
