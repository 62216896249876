import {
  createContext,
  useState,
  useMemo,
} from 'react';

export const HomonymsContext = createContext();

export const HomonymsProvider = ({ children }) => {
  const [postHomonyms, setPostHomonyms] = useState(false);

  const [homonyms, setHomonyms] = useState(null);

  const [preloadQuestionnaire, setPreloadQuestionnaire] = useState(null);

  const context = useMemo(
    () => ({
      preloadQuestionnaire,
      postHomonyms,
      homonyms,
      setPreloadQuestionnaire,
      setPostHomonyms,
      setHomonyms,
    }),
    [
      preloadQuestionnaire,
      postHomonyms,
      homonyms,
      setPreloadQuestionnaire,
      setPostHomonyms,
      setHomonyms],
  );

  return (
    <HomonymsContext.Provider
      value={context}
    >
      {children}
    </HomonymsContext.Provider>
  );
};
