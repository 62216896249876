import React, { useEffect } from 'react';
/// /// EXTERNAL /// ///
// third party libraries //
import { Routes, Route, Navigate } from 'react-router-dom';
import TagManager from 'react-gtm-module';
/// /// INTERNAL /// ///
// third party libraries //
import CommonProvider from 'components/providers/CommonProvider';

const isProduction = process.env.NODE_ENV === 'production';

const App = () => {
  useEffect(() => {
    if (isProduction) {
      const GTM_ID = {
        gtmId: 'GTM-5SX4XDR',
      };
      TagManager.initialize(GTM_ID);
    }
  }, []);

  return (
    <Routes>
      <Route exact path="/:channelID" element={<CommonProvider />} />
      <Route exact path="/:channelID/:POC" element={<CommonProvider />} />
      <Route exact path="/" element={<Navigate to="/prosperia/web" />} />
    </Routes>
  );
};

export default App;
