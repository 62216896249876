// third party libraries //
import axios from 'axios';

export const channelParametersApi = async (channelID) => {
  const url = process.env.REACT_APP_API_GET_CHANNELS;
  return axios.get(url, {
    params: {
      channel: channelID,
    },
  });
};

export const pocParametersApi = async (channelID, POC) => {
  const url = process.env.REACT_APP_API_GET_POCS;
  return axios.get(url, {
    params: {
      channel: channelID,
      poc: POC,
    },
  });
};

export const homonymsApi = async (payload, controller) => {
  const url = process.env.REACT_APP_API_POST_FIND_HOMONYMS;
  return axios
    .post(url, {
      data: payload,
    }, {
      crossdomain: true,
      signal: controller.signal,
    });
};
