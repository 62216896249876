import { useState } from 'react';
// mui //
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
// third party libraries //
import axios from 'axios';
// components //
import Alert from './Alert';

const ExtendedSnackbar = () => {
  const [severity, setSeverity] = useState('');
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);

  axios.interceptors.request.use(
    (request) => request,
    (error) => {
      if (error?.response?.data?.message) {
        setOpen(true);
        setSeverity('error');
        setMessage(error?.response?.data?.message);
      }
      return Promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.data?.message) {
        setOpen(true);
        setSeverity('error');
        setMessage(error?.response?.data?.message);
      }
      return Promise.reject(error);
    },
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        open={open}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default ExtendedSnackbar;
