import { useContext } from 'react';
// mui //
import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
// context //
import { ChannelParametersContext } from 'context/ChannelParametersContext';

const useTheme = () => {
  const { channelParameters } = useContext(ChannelParametersContext);
  const { primaryColor, logoHeader } = channelParameters;
  const primary = primaryColor || '#CFCFCF';
  const lightTheme = createTheme({
    downSm: {
      navBar: '48px',
      backDrop: '53px',
      gapMain: '85px',
    },
    upSm: {
      navBar: '64px',
      backDrop: '58px',
      gapMain: '102px',
    },
    custom: {
      color: '#FFFFFF',
      minWidth: 'min-width:1440px',
      maxWidth: 'max-width:319px',
      maxHeight: 'max-height:709px',
    },
    channel: {
      logo: logoHeader || 'https://calcs-app.s3.amazonaws.com/resources/logo_retinia.png',
    },
    palette: {
      // mode theme
      mode: 'light',
      // primary color
      primary: {
        main: primary,
        // color inside component like datepicker
        contrastText: '#FFFFFF',
      },
      background: {
        default: '#f5f5f5',
      },
      text: {
        primary: grey[800],
      },
    },
    typography: {
      fontFamily: ['Raleway', 'sans-serif'].join(','),
    },
    zIndex: {
      modal: 1500,
    },
    components: {
      MuiYearPicker: {
        styleOverrides: {
          root: {
            '& .PrivatePickersYear-root': {
              '& .Mui-disabled': {
                color: 'rgba(0, 0, 0, 0.3)',
              },
            },
          },
        },
      },
    },
    // components: {
    //   MuiPickersDay: {
    //     styleOverrides: {
    //       // Name of the slot
    //       root: {
    //         "&.Mui-selected": {
    //           color: "#FFFFFF",
    //         },
    //       },
    //     },
    //   },
    //   MuiYearPicker: {
    //     styleOverrides: {
    //       root: {
    //         "& .PrivatePickersYear-root": {
    //           "& .Mui-selected": {
    //             color: "#FFFFFF",
    //           },
    //         },
    //       },
    //     },
    //   },
    //   MuiMonthPicker: {
    //     styleOverrides: {
    //       root: {
    //         "& .MuiTypography-root": {
    //           "&.Mui-selected": {
    //             color: "#FFFFFF",
    //           },
    //         },
    //       },
    //     },
    //   },
    // },
  });

  const darkTheme = createTheme({
    downSm: {
      navBar: '48px',
      backDrop: '53px',
      gapMain: '85px',
    },
    upSm: {
      navBar: '64px',
      backDrop: '58px',
      gapMain: '102px',
    },
    custom: {
      color: 'rgba(0, 0, 0, 1)',
    },
    palette: {
      // mode theme
      mode: 'dark',
      // primary color
      primary: {
        main: primary,
        // color inside component like datepicker
        contrastText: '#FFFFFF',
      },
      background: {
        paper: 'rgba(0, 0, 0, 1)',
      },
    },
    typography: {
      fontFamily: 'Raleway',
    },
    // override styles
    // components: {
    //   MuiPickersDay: {
    //     styleOverrides: {
    //       // Name of the slot
    //       root: {
    //         "&.Mui-selected": {
    //           color: "#FFFFFF",
    //         },
    //       },
    //     },
    //   },
    //   MuiYearPicker: {
    //     styleOverrides: {
    //       root: {
    //         "& .PrivatePickersYear-root": {
    //           "& .Mui-selected": {
    //             color: "#FFFFFF",
    //           },
    //         },
    //       },
    //     },
    //   },
    //   MuiMonthPicker: {
    //     styleOverrides: {
    //       root: {
    //         "& .MuiTypography-root": {
    //           "&.Mui-selected": {
    //             color: "#FFFFFF",
    //           },
    //         },
    //       },
    //     },
    //   },
    // },
  });
  return {
    lightTheme,
    darkTheme,
  };
};

export default useTheme;
