import { useState, useEffect } from 'react';

const useScroll = () => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const moving = window.pageYOffset;
      setVisible(moving === 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  const styleChangeScroll = visible
    ? { top: 0, transition: 'top 0.4s ease-out' }
    : { top: '-80px', transition: 'top 0.4s ease-out' };

  return {
    styleChangeScroll,
  };
};

export default useScroll;
