import {
  createContext,
  useState,
  useMemo,
} from 'react';

export const ChannelParametersContext = createContext();

export const ChannelParametersProvider = ({ children }) => {
  const [isLoaderChannelParameters, setLoaderChannelParameters] = useState(false);

  const [channelParameters, setChannelParameters] = useState({});

  const context = useMemo(
    () => ({
      isLoaderChannelParameters,
      channelParameters,
      setChannelParameters,
      setLoaderChannelParameters,
    }),
    [
      isLoaderChannelParameters,
      channelParameters,
      setChannelParameters,
      setLoaderChannelParameters],
  );

  return (
    <ChannelParametersContext.Provider
      value={context}
    >
      {children}
    </ChannelParametersContext.Provider>
  );
};
