import {
  useContext, useState, lazy, Suspense,
} from 'react';
// mui //
import { Grid, Box } from '@mui/material';
// context //
import { PatientContext } from 'context/PatientContext';
import { PocParametersContext } from 'context/PocParametersContext';
import { InteractionContext } from 'context/InteractionContext';
// hook //
import useResponsive from 'hook/common/useResponsive';
import useChannelParameters from 'hook/common/useChannelParameters';
import usePocParameters from 'hook/common/usePocParameters';
// third party libraires //
import { useParams } from 'react-router-dom';
// components //
import ExtendedNavbar from 'components/molecules/ExtendedNavbar';
import ExtendedSkeleton from 'components/atoms/ExtendedSkeleton';

const EnhancedPatientForm = lazy(() => import('components/organisms/EnhancedPatientForm'));
const PatientQuestions = lazy(() => import('components/organisms/PatientQuestions'));
const PostInteraction = lazy(() => import('components/organisms/PostInteraction'));
const NotFound = lazy(() => import('./NotFound'));
const TermsPrivacy = lazy(() => import('components/atoms/TermsPrivacy'));

const Calcs = () => {
  const { channelID, POC } = useParams();
  useChannelParameters();
  usePocParameters();
  const { downSm, theme, upXl } = useResponsive();

  const [showLegal, setShowLegal] = useState(false);
  const [terms, setTerms] = useState(false);

  const { pocParameters } = useContext(PocParametersContext);

  const { container } = pocParameters;

  const { patient } = useContext(PatientContext);

  const { patientId } = patient;

  const { interaction } = useContext(InteractionContext);
  const { postInteraction } = interaction;

  const handleLegal = (value) => {
    setShowLegal(true);
    setTerms(value);
  };

  const handleTermsPrivacy = () => {
    setShowLegal(false);
    setTerms(false);
  };

  const navbarResponsive = (responsive) => ({
    ...responsive,
    image: {
      style: {
        ...responsive.image.style,
        height: 50,
      },
    },
  });

  let view = (
    <EnhancedPatientForm handleLegal={handleLegal} />
  );

  if (postInteraction) {
    view = <PostInteraction />;
  } else if (patientId) {
    view = <PatientQuestions />;
  }

  const onlyChannel = channelID && !POC;
  const isPocParameters = Object.keys(pocParameters).length > 0;
  const exist = POC && isPocParameters;

  let layouts = null;
  if (onlyChannel) {
    layouts = <NotFound />;
  } else if (exist) {
    layouts = view;
  } else if (!isPocParameters) {
    layouts = <NotFound />;
  }

  let gapContainer = 0;
  let marginTopContainer = 0;
  if (!container) {
    if (downSm) {
      gapContainer = theme.downSm.gapMain;
    } else {
      gapContainer = theme.upSm.gapMain;
    }
  } else if (downSm) {
    marginTopContainer = '40px';
  } else {
    marginTopContainer = '60px';
  }
  const styleContainer = {
    gap: gapContainer,
    marginTop: marginTopContainer,
  };

  const containerGap = exist;

  const handleOnClickIcon = () => {
    window.open('https://www.prosperia.health/es/copy-of-faq', '_blank');
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <ExtendedSkeleton>
        <Grid container sx={containerGap ? styleContainer : { gap: 0 }}>
          {!container && (
            <Grid item xs={12}>
              <ExtendedNavbar
                secondarySx={{
                  ...(upXl && {
                    maxWidth: '1160px !important',
                    margin: '0px auto',
                  }),
                }}
                showIcon
                customResponsive={navbarResponsive}
                onClickIcon={() => handleOnClickIcon()}
              />
            </Grid>
          )}
          <Suspense fallback={<div />}>
            {showLegal ? (
              <TermsPrivacy
                terms={terms}
                handleBack={handleTermsPrivacy}
                termsLogo="https://media-static.s3.amazonaws.com/image/terms_logo.png"
                privacyLogo="https://media-static.s3.amazonaws.com/image/privacy_logo.png"
              />
            ) : (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                item
                xs={12}
              >
                {layouts}
              </Grid>
            )}
          </Suspense>
        </Grid>
      </ExtendedSkeleton>
    </Box>
  );
};

export default Calcs;
