import {
  createContext,
  useState,
  useMemo,
} from 'react';

export const InteractionContext = createContext();

export const InteractionProvider = ({ initializeInteraction, children }) => {
  const [interaction, setInteraction] = useState(initializeInteraction);

  const [questions, setQuestions] = useState({});

  const context = useMemo(
    () => ({
      interaction,
      questions,
      setInteraction,
      setQuestions,
    }),
    [
      interaction,
      questions,
      setInteraction,
      setQuestions],
  );

  return (
    <InteractionContext.Provider
      value={context}
    >
      {children}
    </InteractionContext.Provider>
  );
};
