// mui //
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
// context //
import { PatientProvider } from 'context/PatientContext';
import { ChannelParametersProvider } from 'context/ChannelParametersContext';
import { PocParametersProvider } from 'context/PocParametersContext';
import { InteractionProvider } from 'context/InteractionContext';
import { HomonymsProvider } from 'context/HomonymsContext';
// context //
import InstructionsProvider from 'context/InstructionsContext';
// components //
import ExtendedSnackbar from 'components/atoms/ExtendedSnackbar';
import Calcs from 'components/pages/Calcs';
// hook //
import useTheme from 'hook/useTheme';

const Theme = () => {
  const { lightTheme } = useTheme();
  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <Calcs />
      <ExtendedSnackbar />
    </ThemeProvider>
  );
};

const initializePatient = {
  name: '',
  lastName: '',
  birthDate: null,
  patientId: '',
  enabledTerms: false,
  enabledPrivacy: false,
  operatorField: '',
  horusIP: '',
  phone: '',
  email: '',
  phoneCode: '+52',
  countryCode: 'MX',
  age: '',
  pairingId: '',
};

const initializeInteraction = {
  endTime: '',
  startTime: '',
  postInteraction: false,
};

const CommonProvider = () => (
  <ChannelParametersProvider>
    <PocParametersProvider>
      <PatientProvider initializePatient={initializePatient}>
        <HomonymsProvider>
          <InstructionsProvider>
            <InteractionProvider initializeInteraction={initializeInteraction}>
              <Theme />
            </InteractionProvider>
          </InstructionsProvider>
        </HomonymsProvider>
      </PatientProvider>
    </PocParametersProvider>
  </ChannelParametersProvider>
);

export default CommonProvider;
