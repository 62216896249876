import React, {
  createContext,
  useState,
  useMemo,
} from 'react';

export const PatientContext = createContext();

export const PatientProvider = ({ initializePatient, children }) => {
  const [postPatient, setPostPatient] = useState(false);

  const [patient, setPatient] = useState(initializePatient);

  const context = useMemo(() => ({
    initializePatient,
    postPatient,
    patient,
    setPostPatient,
    setPatient,
  }), [
    initializePatient,
    postPatient,
    patient,
    setPostPatient,
    setPatient,
  ]);

  return (
    <PatientContext.Provider
      value={context}
    >
      {children}
    </PatientContext.Provider>
  );
};
