// mui //
import { Grid } from '@mui/material';

const ExtendedGrid = (props) => {
  const {
    children, gap, sx, secondarySx, xs, sm, md, ...rest
  } = props;
  return (
    <Grid container justifyContent="center" sx={sx} {...rest}>
      <Grid
        item
        container
        direction="column"
        sx={secondarySx}
        gap={gap}
        xs={xs}
        sm={sm}
        md={md}
      >
        {children}
      </Grid>
    </Grid>
  );
};
export default ExtendedGrid;
ExtendedGrid.defaultProps = {
  xs: 12,
  sm: 11,
  md: 9,
};
