import React, {
  createContext, useState, useEffect, useMemo,
} from 'react';
// third party libraries //
import { useParams } from 'react-router-dom';
// services //
import instructionsApi from 'services/instructionsService';

export const InstructionsContext = createContext();

const InstructionsProvider = ({ children }) => {
  const { channelID, POC } = useParams();
  const [instructionsData, setInstructionsData] = useState({});

  const [instructions, setInstructions] = useState([]);
  const { forms } = instructions;

  useEffect(() => {
    if (channelID && POC) {
      const getInstructions = async () => {
        instructionsApi(channelID, POC)
          .then((info) => {
            const result = info.data;
            setInstructions(result.data);
          })
          .catch((err) => {
            console.log(err);
          });
      };
      getInstructions();
    }
  }, [channelID, POC]);

  const context = useMemo(
    () => ({
      forms,
      instructions,
      setInstructions,
      instructionsData,
      setInstructionsData,
    }),
    [
      forms,
      instructions,
      setInstructions,
      instructionsData,
      setInstructionsData],
  );

  return (
    <InstructionsContext.Provider value={context}>
      {children}
    </InstructionsContext.Provider>
  );
};

export default InstructionsProvider;
