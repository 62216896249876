// mui
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useResponsive = () => {
  const theme = useTheme();

  /**
   *  xs
   */
  const upXs = useMediaQuery(theme.breakpoints.up('xs'));

  /**
   *  sm
   */
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));

  /**
   *  md
   */
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const downMd = useMediaQuery(theme.breakpoints.down('md'));

  /**
   *  lg
   */
  const upLg = useMediaQuery(theme.breakpoints.up('lg'));
  const downLg = useMediaQuery(theme.breakpoints.down('lg'));

  /**
   *  xl
   */
  const upXl = useMediaQuery(theme.breakpoints.up('xl'));
  const downXl = useMediaQuery(theme.breakpoints.down('xl'));

  /**
   *  custom breakpoints
   */
  const minWidth = useMediaQuery(`(${theme.custom.minWidth})`);
  const maxWidth = useMediaQuery(`(${theme.custom.maxWidth})`);
  const maxHeight = useMediaQuery(`(${theme.custom.maxHeight})`);

  return {
    upXs,
    upSm,
    downSm,
    upMd,
    downMd,
    upLg,
    downLg,
    upXl,
    downXl,
    minWidth,
    maxWidth,
    maxHeight,
    theme,
  };
};

export default useResponsive;
