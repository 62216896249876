import { useContext } from 'react';
// mui //
import { Skeleton } from '@mui/material';
// context //
import { ChannelParametersContext } from 'context/ChannelParametersContext';
import { PocParametersContext } from 'context/PocParametersContext';
// third party libraries //
import { useParams } from 'react-router-dom';

const ExtendedSkeleton = ({ children }) => {
  const { POC } = useParams();

  const { isLoaderChannelParameters } = useContext(ChannelParametersContext);

  const { isLoaderPocParameters } = useContext(PocParametersContext);

  const loader = POC ? isLoaderPocParameters : isLoaderChannelParameters;

  if (!loader) {
    return (
      <Skeleton
        animation="wave"
        variant="rectangular"
        height="100vh"
        width="100%"
      />
    );
  }

  return children;
};

export default ExtendedSkeleton;
