// third party libraries //
import axios from 'axios';

const instructionsApi = async (channelID, POC) => {
  const url = `${process.env.REACT_APP_API_GET_INSTRUCTIONS}/${channelID}/${POC}/instructions`;
  return axios
    .get(url);
};

export default instructionsApi;
