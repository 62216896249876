import { useContext, useEffect, useMemo } from 'react';
// context //
import { ChannelParametersContext } from 'context/ChannelParametersContext';
// third party libraries //
import { useParams } from 'react-router-dom';
// services //
import { channelParametersApi } from 'services/commonService';

const useChannelParameters = () => {
  const { channelID } = useParams();

  const {
    channelParameters,
    setChannelParameters,
    setLoaderChannelParameters,
  } = useContext(ChannelParametersContext);

  const parameters = useMemo(() => (
    channelParameters
  ), [channelParameters]);

  /**
   *  invoke the lambda to get the data from the channel
   */
  useEffect(() => {
    let isSubscribed = true;
    const withoutParams = !parameters || Object.keys(parameters).length === 0;
    if (channelID && withoutParams) {
      const getChannel = () => {
        channelParametersApi(channelID)
          .then((info) => {
            if (isSubscribed) {
              const result = info.data;
              const channelParams = result.data;
              setChannelParameters(channelParams);
              setLoaderChannelParameters(true);
            }
          })
          .catch(() => {
            setLoaderChannelParameters(false);
          });
      };
      getChannel();
    }
    return () => {
      isSubscribed = false;
    };
  }, [
    channelID,
    parameters,
    setChannelParameters,
    setLoaderChannelParameters]);

  return {};
};

export default useChannelParameters;
