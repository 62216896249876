import { useContext, useEffect, useMemo } from 'react';
// context //
import { PocParametersContext } from 'context/PocParametersContext';
// third party libraries  //
import { useParams } from 'react-router-dom';
// services //
import { pocParametersApi } from 'services/commonService';

const usePocParameters = () => {
  const { channelID, POC } = useParams();

  const {
    pocParameters, setPocParameters,
    setLoaderPocParameters,
  } = useContext(PocParametersContext);

  const parameters = useMemo(() => pocParameters, [pocParameters]);

  useEffect(() => {
    let isSubscribed = true;
    const withoutParams = !parameters || Object.keys(parameters).length === 0;
    if (channelID && POC && withoutParams) {
      const getPOCParemeters = () => {
        pocParametersApi(channelID, POC).then((info) => {
          if (isSubscribed) {
            const result = info.data;
            setPocParameters(result.data);
            setLoaderPocParameters(true);
          }
        })
          .catch(() => {
            setLoaderPocParameters(false);
          });
      };
      getPOCParemeters();
    }
    return () => {
      isSubscribed = false;
    };
  }, [channelID, POC, parameters, setLoaderPocParameters, setPocParameters]);

  return {};
};

export default usePocParameters;
