import {
  createContext,
  useState,
  useMemo,
} from 'react';

export const PocParametersContext = createContext();

export const PocParametersProvider = ({ children }) => {
  const [isLoaderPocParameters, setLoaderPocParameters] = useState(false);

  const [isVerification, setVerification] = useState(null);

  const [pocParameters, setPocParameters] = useState({});

  const context = useMemo(
    () => ({
      isVerification,
      isLoaderPocParameters,
      pocParameters,
      setVerification,
      setLoaderPocParameters,
      setPocParameters,
    }),
    [
      isVerification,
      isLoaderPocParameters,
      pocParameters,
      setVerification,
      setLoaderPocParameters,
      setPocParameters],
  );

  return (
    <PocParametersContext.Provider
      value={context}
    >
      {children}
    </PocParametersContext.Provider>
  );
};
