import React, { useState, useContext } from 'react';
// mui //
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
} from '@mui/material';
import Icon from '@mui/material/Icon';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout';
// components //
import ExtendedButton from 'components/atoms/ExtendedButton';
import ExtendedGrid from 'components/atoms/ExtendedGrid';
// context //
import { PatientContext } from 'context/PatientContext';
// hook //
import useResponsive from 'hook/common/useResponsive';
import useScroll from 'hook/useScroll';

const ExtendedNavbar = ({
  user,
  showIcon,
  showPatient,
  showPairing,
  showUser,
  showChangePassword,
  showScreenings,
  onClickIcon,
  customResponsive,
  handleLogout,
  handleChangePassword,
  toolTipLogout,
  ...rest
}) => {
  const { styleChangeScroll } = useScroll();
  /**
   *  responsive breakpoints and theme
   */
  const { downSm, theme } = useResponsive();
  const primary = theme.palette.primary.main;
  const { grey } = theme.palette;
  const customColor = theme.custom.color;
  const { logo } = theme.channel;

  /**
   *  patient data
   */

  const [anchorEl, setAnchorEl] = useState(null);
  const { patient } = useContext(PatientContext);

  const zIndexAppBar = theme.zIndex.snackbar;

  const open = Boolean(anchorEl);

  /**
   *  responsive style
   */
  let responsive = {
    typography: {
      patient: {
        variant: downSm ? 'body2' : 'body1',
        style: { color: 'white', fontWeight: 600 },
      },
      text: {
        style: { fontSize: '10px', color: 'white', fontWeight: 500 },
      },
      user: {
        variant: downSm ? 'body2' : 'body1',
        style: { color: 'white', fontWeight: 600, marginRight: '10px' },
      },
      profile: {
        variant: 'body1',
        style: {
          color: grey[800],
          fontWeight: 600,
          whiteSpace: 'normal',
          textAlign: 'center',
        },
      },
      email: {
        variant: 'body2',
        style: {
          color: grey[800],
          whiteSpace: 'normal',
          wordBreak: 'break-all',
        },
      },
      countFindings: {
        variant: 'h4',
        style: {
          color: grey[800],
          fontWeight: 700,
        },
      },
      logout: {
        variant: 'subtitle2',
        style: {
          color: primary,
          fontWeight: 600,
          textTransform: 'initial',
        },
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingTop: '4px',
      paddingBottom: '4px',
      width: '100%',
      ...(downSm
        ? { height: theme.downSm.navBar }
        : { height: theme.upSm.navBar }),
    },
    logoutContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    icon: {
      borderRadius: '1em',
      border: '2px solid white',
      color: customColor,
      padding: '2px',
      transition: '0.5s',
      width: downSm ? '20px' : '25px',
      height: downSm ? '20px' : '25px',
      ...(!downSm && {
        '&:hover': {
          background: customColor,
          color: primary,
        },
      }),
    },
    iconUser: {
      fontSize: 30,
      color: 'white',
    },
    iconLogout: {
      width: downSm ? '20px' : '24px',
      height: downSm ? '20px' : '24px',
      color: 'white',
    },
    userContainer: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '10px',
      borderRadius: '8px',
    },
    image: {
      style: {
        width: 100,
        maxHeight: 50,
        objectFit: 'contain',
      },
    },
    profileContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
    },
    profileNameContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
    menuItem: {
      '&:hover': { backgroundColor: 'transparent' },
      cursor: 'auto',
    },
    divider: {
      background: grey[500],
      width: '100%',
      height: '1px',
    },
    findings: {
      display: 'flex',
      flexWrap: 'nowrap',
      gap: '10px',
      justifyContent: 'space-between',
    },
  };

  if (customResponsive) responsive = customResponsive(responsive);

  const handleClickLogout = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseLogout = () => {
    setAnchorEl(null);
  };

  const patientComponent = showPatient && patient && patient?.patientId && (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography component="span" sx={responsive.typography.text.style}>
        NOMBRE DE PACIENTE
      </Typography>
      <Typography
        component="span"
        sx={responsive.typography.patient.style}
        variant={responsive.typography.patient.variant}
      >
        {patient?.name}
        {' '}
        {patient?.lastName}
      </Typography>
    </Box>
  );

  const pairingComponent = showPairing && patient && patient?.pairingId && (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography component="span" sx={responsive.typography.text.style}>
        ID DE SINCRONIZACIÓN
      </Typography>
      <Typography
        component="span"
        sx={responsive.typography.patient.style}
        variant={responsive.typography.patient.variant}
      >
        {patient?.pairingId}
      </Typography>
    </Box>
  );

  const monthText = `en el mes de ${user?.month}`;

  const profileComponent = (
    <Box
      sx={responsive.profileContainer}
    >
      <Box sx={responsive.profileNameContainer}>
        <Icon
          className="material-symbols-outlined material-symbols-fill"
          sx={{
            color: primary,
            fontSize: 40,
            marginBottom: '10px',
          }}
        >
          account_circle
        </Icon>
        {user?.name && (
          <Typography
            component="span"
            sx={responsive.typography.profile.style}
            variant={responsive.typography.profile.variant}
          >
            {user?.name}
          </Typography>
        )}
        {user?.email && (
          <Typography
            component="span"
            sx={responsive.typography.email.style}
            variant={responsive.typography.email.variant}
          >
            {user?.email}
          </Typography>
        )}
      </Box>
      {showScreenings && (
        <Box sx={responsive.findings}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
          >
            <Typography
              component="span"
              sx={responsive.typography.email.style}
              variant={responsive.typography.email.variant}
            >
              Total de tamizajes
            </Typography>
            <Typography
              component="span"
              sx={responsive.typography.email.style}
              variant={responsive.typography.email.variant}
            >
              {monthText}
            </Typography>
          </Box>
          <Typography
            component="span"
            sx={responsive.typography.countFindings.style}
            variant={responsive.typography.countFindings.variant}
          >
            {user?.screenings}
          </Typography>
        </Box>
      )}
      <Box sx={responsive.divider} />
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
      >
        <ExtendedButton
          variant="four"
          aria-label="menu-button"
          id="menu-button"
          size="small"
          data-cy="logout-button"
          startIcon={<LogoutIcon />}
          onClick={() => handleLogout()}
          customStyle={{
            width: 'auto',
          }}
        >
          <Typography
            sx={responsive.typography.logout.style}
            variant={responsive.typography.logout.variant}
          >
            Cerrar sesión
          </Typography>
        </ExtendedButton>
      </Box>
    </Box>
  );

  const userComponent = showUser && user && (
    <>
      <IconButton
        sx={responsive.userContainer}
        aria-label="menu"
        id="menu"
        data-cy="menu-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClickLogout}
      >
        <Typography
          component="span"
          sx={responsive.typography.user.style}
          variant={responsive.typography.user.variant}
        >
          {user?.name}
        </Typography>

        <Icon
          className="material-symbols-outlined material-symbols-fill"
          sx={responsive.iconUser}
        >
          account_circle
        </Icon>

        <KeyboardArrowDownIcon sx={responsive.iconLogout} />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseLogout}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            sx: {
              width: 'auto',
              padding: '10px',
              borderRadius: '16px',
              maxWidth: '285px',
            },
          },
        }}
      >
        <MenuItem
          sx={responsive.menuItem}
        >
          {profileComponent}
        </MenuItem>
      </Menu>
    </>
  );

  const iconComponent = showIcon && (
    <IconButton
      aria-label="question-button"
      id="question-button"
      sx={responsive.iconButton}
      onClick={onClickIcon}
    >
      <QuestionMarkIcon sx={responsive.icon} />
    </IconButton>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        sx={{ zIndex: zIndexAppBar, background: primary }}
        style={styleChangeScroll}
      >
        <Toolbar>
          <ExtendedGrid md={12} {...rest}>
            <Box sx={responsive.container}>
              {logo && (
                <img src={logo} alt="Logo" style={responsive.image.style} />
              )}
              <Box sx={{ display: 'flex', gap: '24px' }}>
                {patientComponent}
                {pairingComponent}
              </Box>
              <Box sx={responsive.logoutContainer}>
                {userComponent}
                {iconComponent}
              </Box>
            </Box>
          </ExtendedGrid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default ExtendedNavbar;
